import React,{ useState ,useEffect} from 'react';

import { Dropdown } from '@tencent/tea-component';
import { Avatar, AvatarPopup, loadingbar } from '@tencent/sr-ui-component';
import "./index.scss"
const url = require('url');
import axios from '../../utils/axios'

export default function Logo(props){

  // const [user,setUser] = useState({staffId:null,staffName:null});

  // // 异步请求获取用户信息的接口，返回后更新组件
  // useEffect(() => {
  //   if( !user.staffId ){
  //     axios.post("/api/getUser", {})
  //     .then(function (response) {
  //       if ( response.success ) {
  //         setUser( response.data );
  //       } else if(response.data.code == 403 ){
  //         window.location.href = "https://passport.woa.com/modules/passport/signin.ashx?url=" + encodeURIComponent(window.location.href);
  //       }else {
  //         const srcUrl = url.parse(window.location.href);
  //         window.location.href = "http://"+ srcUrl.host + "/nopermission";
  //       }
  //     }) ;
  //   }
  // });


  return (
    <div className={'logo'}>
      <Dropdown
        button={<Avatar src={<img className={'logoImage'} alt="" src={"https://we-retail-static-1300977798.file.myqcloud.com/web/icons/logo-ym.png"} />} />}
        appearance="pure"
        trigger="hover"
        placement="bottom-start"
        placementOffset={[-8, 12]}
      >
        {/* <AvatarPopup className={'avatar'}>
          <AvatarPopup.Item
            label="登录账号"
            operation={<span className="words">{user.staffName}</span>}
            // icon
            onClick={() => {
            }}
          />
          <AvatarPopup.Item label="退出登录" onClick={() => {
              // history.push('/user/user-center'); onClick={doLogout}
              // 获取host，然后推出
              const srcUrl = url.parse(window.location.href);
              window.location.href = "http://"+ srcUrl.host + "/_logout/?url=" + encodeURIComponent(window.location.href);
            }}/>
        </AvatarPopup> */}
      </Dropdown>
    </div>
    );
}