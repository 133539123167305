import React, { useState, useEffect, useRef } from 'react';
import {NavLink,BrowserRouter,Route,Redirect} from 'react-router-dom';
import { Table, Card, Layout,Pagination,notification,Switch,Button,Text,PopConfirm } from "@tencent/tea-component";
const { Body, Content } = Layout;
const { sortable, selectable, scrollable,radioable } = Table.addons;
import FilterPannel from '../../common/FilterPannel'
import axios from '../../utils/axios'
const qs = require('qs');

const metaData = {
    // dataName:"店铺",
    recordKey:"key",
    columns:[
        {
            key: "key",
            header: "记录ID",
            width:"140px"
        },
        {
            key: "ky",
            header: "系统ID",
            width:"140px",
            _filter:{
                component: "Select",
                options:[
                    {value:"all",text:"全部"},
                    {value:"fy",text:"枫页大货"},
                    {value:"xj",text:"蹊径制作端"},
                    {value:"fymall",text:"枫页小店制作端"},
                    {value:"fymallAdmin",text:"枫页小店运营平台"},
                ],
                default:"all",
                placeholder: '',
                size:"s"
            },
        },
        {
            key: "kyName",
            header: "系统名称",
            width:"140px",
            render: row => {
                switch(row.ky){
                  case 'fy':
                    return "枫页大货";
                  case 'xj':
                    return "蹊径制作端";
                case 'fymall':
                    return "枫页小店制作端";
                case 'fymallAdmin':
                    return "枫页小店运营平台";
                  default:
                    return row.ky;
              }},
        },
        {
            key: "refer_id",
            header: "页面",
            _filter:{
                component: "Input",
                placeholder: '',
            },
        },
        {
            key: "bury_id",
            header: "埋点ID",
            width:"140px",
            _filter:{
                component: "Input",
                placeholder: '',
            },
        },
        {
            key: "state",
            header: "是否落地",
            width:"150px"
        },
        {
            key: "operation",
            header: "操作",
            width:"120px",
            render: row => (<></>),
        },
    ]
};
const { tableColumns, columnFilters, initFilter } = FilterPannel.initFilter( metaData.columns );

/**
 * 1、打开页面则查询一个分页的记录，然后渲染
 * 2、渲染界面，查询条件 系统ID 页面ID 按钮ID 三个查询条件，查询结果按创建时间排序
 * 3、表格，表格直接点击按钮既可以表示启用或者关闭
 * 4、启用和关闭都要写redis
 * @param {*} props 
 * @returns 
 */
function Config( props ) {
    let page = {pageIndex:1,pageSize:10};
    const [data,setData] = useState({records:[],recordCount:0,...page});
    const [filter,setFilter] = useState( initFilter );
    // 左侧多选框
    const [selectedKeys, setSelectedKeys] = useState([]);

    // 收集 pannel返回的筛选条件
    function collectFilter( keyOrObj ){
        if( (typeof keyOrObj) == "object"){
            setFilter({...filter,...keyOrObj});
            return ;
        }
        return (value) => {
            let newFilter = {...filter,[keyOrObj]:value} ;
            if(value == ""){
                delete newFilter[keyOrObj]
            }
            setFilter( newFilter );
        }
    }

    function clearFilter( ){
        setFilter(initFilter);
    }

    // 发起查询
    async function search(){
        axios.post("/config/list", {...page,...filter})
          .then(function (response) {
            if (response && response.data) {
              setData(response.data);
            } else {
              notification.error({
                title: '失败提醒',
                description: `查询失败，请联系管理员！`,
              });
              return false;
            }
          });
    }

    // 定制 按钮
    metaData.columns.map((column)=>{
        if( column.key != "state" ) return ;
        column.render = (row)=>( 
        <Switch 
            loading = {loadingState[row.key]}
            disabled = {loadingState[row.key]}
            value = { row.state == 0 ? false:true }
            onChange = { ( value ) => change(value,row) }
        > { row.state == 0 ? "不落地":"落地" } </Switch> 
        );
    });
    const [loadingState,setLoadingState] = useState( {} );

    // 定制 删除操作
    metaData.columns.map((column)=>{
        if( column.key != "operation" ) return ;
        column.render = (row)=>( 
            <PopConfirm
      title="确定要删除？"
      message="解除后，该数据将不再落地，如果前端继续上报，配置记录将会再次出现。"
      footer={close => (
                        <>
                        <Button type="link" onClick={() => {
                                axios.post("/config/delete",{ keys:[ row.key ]} )
                                .then(function (response) {
                                    if (response && response.success) {
                                        page = {
                                            pageIndex:data.pageIndex,
                                            pageSize:data.pageSize
                                        }
                                        search()
                                        notification.success({
                                            title: '成功提醒',
                                            description: `删除成功！`,
                                            });
                                    } else {
                                        notification.error({
                                        title: '失败提醒',
                                        description: `删除失败，请联系管理员！`,
                                        });
                                    }
                                    close();
                                });
                            }
        }  > 确定 </Button>
                        <Button type="text" onClick={() => { close(); }} > 取消 </Button>
                        </>
                    )}
                    placement="top-start"
                    >
                <Button type="link" > 删除 </Button>
            </PopConfirm>
        );
    });

    /**
     * 控制按钮状态，发起更新的请求，返回后更新状态，响应状态信息
     * @param {*} value 
     * @param {*} row 
     */
    function change(value,row){
        loadingState[row.key] = true ;
        setLoadingState({...loadingState});
        axios.post("/config/save",{value,keys:[row.key]})
        .then(function (response) {
            if (response && response.success) {
                row.state = response.data ; // 这种方式可以更新单条记录的值，一个hack方案
            } else {
                notification.error({
                title: '失败提醒',
                description: `查询失败，请联系管理员！`,
                });
            }
            loadingState[row.key] = false ;
            setLoadingState({...loadingState});
        });
    }

    function submit(){
        axios.post("/config/save",{value:true,keys:selectedKeys})
        .then(function (response) {
            if (response && response.success) {
                search();
            } else {
                notification.error({
                title: '失败提醒',
                description: `查询失败，请联系管理员！`,
                });
            }
        });
    }
    function cancel(){
        axios.post("/config/save",{value:false,keys:selectedKeys})
        .then(function (response) {
            if (response && response.success) {
                search();
            } else {
                notification.error({
                title: '失败提醒',
                description: `查询失败，请联系管理员！`,
                });
            }
        });
    }
    function del( key ){
        return ( ) => {
            axios.post("/config/delete",{ keys:[key]} )
            .then(function (response) {
                if (response && response.success) {
                    notification.success({
                        title: '成功提醒',
                        description: `删除成功！`,
                        });
                } else {
                    notification.error({
                    title: '失败提醒',
                    description: `删除失败，请联系管理员！`,
                    });
                }
            });
        }
    }

    return (
        <Layout>
            <Body>
                <Content>
                    <Content.Body>
                    <FilterPannel 
                        columns={columnFilters}
                        search={search}
                        clearFilter={clearFilter}
                        filter={filter}
                        collectFilter={collectFilter} />
                        <Card >
                        <Card.Body>
                            <div style={{paddingBottom:"10px"}}>
                                <Text theme="label" style={{display:"inline-block",verticalAlign:"middle",paddingRight:"10px",width:"80px"}}>已选择{selectedKeys.length}项</Text>
                                { selectedKeys.length <= 1 ? 
                                <>
                                    <Button type="weak" style={{verticalAlign:"middle",lineHeight:"30px"}} disabled > 批量确认</Button>
                                    <Button type="weak" style={{verticalAlign:"middle",lineHeight:"30px",marginLeft:"10px"}} disabled > 批量取消</Button> 
                                </> : 
                                <>
                                    <Button type="primary" style={{verticalAlign:"middle",lineHeight:"30px"}} onClick={submit} data-bury-id={"批量确认"}> 批量确认</Button>
                                    <Button type="weak" style={{verticalAlign:"middle",lineHeight:"30px",marginLeft:"10px"}} onClick={cancel} data-bury-id={"批量取消"}> 批量取消</Button> 
                                </>
                                }
                            </div>
                            <Table
                                verticalTop
                                records = { data.records }
                                recordKey = {metaData.recordKey}
                                columns = {metaData.columns}
                                addons = {[
                                    scrollable({
                                        minWidth: 980,
                                      }),
                                    selectable({
                                        value: selectedKeys,
                                        onChange: (keys, context) => {
                                          setSelectedKeys(keys);
                                        },
                                        rowSelect: false,
                                      }),
                                  ]}
                            />
                            <Pagination
                                recordCount={data.recordCount}
                                pageIndex={data.pageIndex}
                                pageSize={data.pageSize}
                                pageSizeOptions={[10,30,50,100,1000,5000]}
                                onPagingChange={(value)=>{
                                    page = value;
                                    search();
                                }}
                                />
                        </Card.Body>
                        </Card>
                    </Content.Body>
                </Content>
            </Body>
        </Layout>
    )
}

export default Config;