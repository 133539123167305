import React from 'react';
import axios from 'axios';
import { Notification } from '@tencent/sr-ui-component';

const codeMessage = {
    400: '请求错误。(400)',
    401: '未授权的访问。(401)',
    403: '访问被禁止。(403)',
    404: '资源不存在。(404)',
    500: '服务器繁忙，请稍后再试。(500)',
    502: '服务器繁忙，请稍后再试。(502)',
    503: '服务器繁忙，请稍后再试。(503)',
    504: '请求超时，请稍后再试。（504）',
};

// 响应拦截
axios.interceptors.response.use(function (response) {
    // 2** 
    // console.log(response);
    return response.data;
  }, function (error) {
    // ! 2**
    if( codeMessage[error.response.status]){
        Notification.error(codeMessage[error.response.status] , 2000);
        return Promise.resolve(error.response.data);
    }else{
        Notification.error(error.message, 2000);
    }
    return Promise.resolve(error.response);
  });

export default axios 