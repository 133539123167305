/**
 * 参考 http://sr-component.pages.oa.com/component/aside#api
 * 可以配置多级菜单
 */
const menuData = [
    {
      code: 'M0001',
      id: 1,
      name: '无埋点配置',
      order: 1,
      parentId: 0,
      type: 1,
      url: '/config',
      icon: '',
      children: []
    }
  ];
  export default menuData;
