import React , { useState } from 'react';
import {
    Form,
    Input,
    RadioGroup,
    Radio,
    CheckboxGroup,
    Checkbox,
    InputNumber,
    Card,
    Segment,
    Button,
    Switch,
    Text,
    DatePicker,
    Icon,
  } from "@tencent/tea-component";

import {
    FilterFormController,
    ExportFloatLayer,
    Notification,
    getUrlQueryData,
} from '@tencent/sr-ui-component';
import moment from "moment";
import { CSVLink } from "react-csv";
import ExportIcon from  '../../../public/images/export.svg';
import './index.scss';
import _ from 'lodash';

const {
    InputAdornment,
    Controller,
    Select,
    SubmitBtn,
    ResetBtn,
    ExportBtn,
    ActionPanel,
    Item,
  } = FilterFormController;

const options = [
    { text: "近60天", value: "1" },
    { text: "近30天", value: "2" },
    { text: "近7天", value: "3" },
    { text: "昨天", value: "4" },
    { text: "今天", value: "5" },
  ];

  const rangeMap = {
      "1": [moment().subtract(59, "d"), moment()],
      "2": [moment().subtract(29, "d"), moment()],
      "3": [moment().subtract(6, "d"), moment()],
      "4": [moment().subtract(1, "d"), moment().subtract(1, "d")],
      "5": [moment(), moment()],
  };

 export default function FilterPannel(props){
     const {columns,filter} = props ;
    // 文件下载的配置 props.downloadFileName
    const csvheaders = [];
    columns.map( (column)=>{
        if( !column.download ) return ;
        csvheaders.push({label:column.header,key:column.key});
    });

    // 日期控件
    const [segmentValue1, setSegmentValue1] = useState(null);
    const [value1, setValue1] = useState(null);

    function setRangeValue(value, setter) {
      const range = rangeMap[value];
      setter(range);
      return range;
    }

    function getSegmentValue(dates) {
      const [start, end] = dates;
      // Object.entries()方法返回一个给定对象自身可枚举属性的键值对数组
      // reduce() 方法对数组中的每个元素执行一个由您提供的reducer函数
      return Object.entries(rangeMap).reduce((prev, cur) => {
        const [key, range] = cur;
        if (start.isSame(range[0], "day") && end.isSame(range[1], "day")) {
          return key;
        }
        return prev;
      }, null);
    }
    // end 日期控件

    // 处理搜索字段的排序
    let orderedColumns = [];
    columns.map( (column)=>{
        if( !column._filter ) return ;
        if( column._filter.order ){
            orderedColumns[column._filter.order] = column ;
        }else{
            orderedColumns[orderedColumns.length] = column;
        }
    });
    orderedColumns = _.without(orderedColumns, undefined);

    return (
    <Card>
        <div className="filterFormControllerWrap">
        <FilterFormController handleSubmit={props.search} >
            {
              columns.map( (column) => {
                    if( !column ) return ;
                    switch(column.component){
                        case "Input":
                            return (
                                <Item title={column.header} key={column.key}>
                                    <Input value={filter[column.key] || ''} size={column.size} placeholder={`请输入`+column.header} onChange={props.collectFilter(column.key)}/>
                                </Item>
                            )
                        case "RangePicker":
                            return (
                                <Item title={column.header} key={column.key}>
                                    { column.showSegment !== false ? <Segment
                                        value={ getSegmentValue([moment(filter[column.key+"From"]), moment(filter[column.key+"To"])]) || segmentValue1 }
                                        options={options}
                                        onChange={value => {
                                            setSegmentValue1(value);
                                            const range = setRangeValue(value, setValue1);
                                            props.collectFilter({
                                                [column.key+"From"]:range[0].format("YYYY-MM-DD"),
                                                [column.key+"To"]:range[1].format("YYYY-MM-DD")
                                            });
                                        }}
                                    /> : null}
                                    <DatePicker.RangePicker
                                        value= {[moment(filter[column.key+"From"]), moment(filter[column.key+"To"])] || value1}
                                        onChange={
                                            (value)=>{
                                                setValue1(value);
                                                setSegmentValue1(getSegmentValue(value));
                                                props.collectFilter({
                                                    [column.key+"From"]:value[0].format("YYYY-MM-DD"),
                                                    [column.key+"To"]:value[1].format("YYYY-MM-DD")
                                                });
                                            }
                                        }
                                        />
                                    </Item>
                            )
                        case "RangeSearch":
                            return (
                                <Item title={column.header} className="large-input" key={column.key}>
                                    <InputNumber min={0} value={filter[column.key+"From"] || ''} placeholder={column.placeholder} onChange={props.collectFilter(column.key+"From")}/>
                                  <div className="rangeSearchExplode">至</div>
                                    <InputNumber min={filter[column.key+"From"] ||0} value={filter[column.key+"To"] || ''} placeholder={column.placeholder} onChange={props.collectFilter(column.key+"To")}/>
                                </Item>
                            )
                        case "Select":
                            return (
                                <Item title={column.header} className="large-input" key={column.key}>
                                    <Select
                                        type="simulate"
                                        appearance="button"
                                        options={column.options}
                                        value={ filter[column.key] }
                                        onChange={ props.collectFilter(column.key) }
                                        placeholder={column.placeholder}
                                        size={column.size || "m"}
                                        listHeight={300}
                                        width={200}
                                        />
                                </Item>
                            )
                    }
                })
            }
            <ActionPanel>
            <SubmitBtn data-bury-id={"查询"} >查询</SubmitBtn>
            <Button style={{marginLeft: 20}} onClick={props.clearFilter} data-bury-id={"重置"}>重置</Button>
            {props.downloadFileName &&
                <>
                <span style={{
                  width: 14,
                  height: 14,
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'inherit',
                  fontSize: '0%',
                  marginLeft: 15,
                  marginRight: 8,
                  backgroundImage: { ExportIcon },
                }}>
                  <img src={ExportIcon} width='100%' />
                </span>
                <Button type="link" onClick={props.download} >导出{props.downloadFileName}</Button>
                <span type="link" onClick={()=>{}} style={{paddingLeft:10, color:"#888888", verticalAlign: "middle",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "inherit"}}>单次导出最多支持<font color="red">1000</font>条数据</span>
                </>
            }
            </ActionPanel>

            </FilterFormController>
        </div>
    </Card>);
 }


 /**
  * 初始化 pannel 的选择条件
  * */
const initFilter = ( columns )=>{
    // 筛选字段
    let columnFilters = [];
    // 时间或者select筛选设置默认值
    let initFilter = {};
    // 表格字段
    let tableColumns = [];
    columns.map( (column)=>{
        if( column.key ) {
          tableColumns.push(column);
        }
        else {
          column.key = column.name;
        }
        if( !column._filter ) return ;
        column._filter.key = column.key || column.name;
        column._filter.header = column.filterHeader || column.header;
        columnFilters.push(column._filter);
        if(column._filter.component == "RangePicker"){
            initFilter = {...initFilter,
                [column.key+"From"]:moment().add(...column._filter.initRange).format("YYYY-MM-DD"),
                [column.key+"To"]:moment().format("YYYY-MM-DD")
            }
        }
        if(column._filter.component == "Select"){
          initFilter = {...initFilter,
            [column.key]:column._filter.default
          }
        }
    });
    columnFilters.sort(function(a, b){
      return a.order > b.order ? 1 : 0;
    });
    return { tableColumns, columnFilters, initFilter} ;
 }

 FilterPannel.initFilter = initFilter
