import React,{ useState,useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { Layout,Container } from '@tencent/sr-ui-component';
const { Body, Main, Aside } = Layout;
import {NavLink,BrowserRouter,Route,Switch,Redirect} from 'react-router-dom'

import menuData from './menuData';
import Config from './routes/Config';
import Logo from './routes/Logo';
const url = require('url');
const nobury = require('@tencent/xmonitor').default;
new nobury({appid:"fymallAdmin"});

const App = (props) => {
  // 检查url是否带着路径，如果带着则用于初始化
  const srcUrl = url.parse(window.location.href);
  const [, currentRootPath] = srcUrl.pathname.split('/');

  const [rootPath, setRootPath] = useState(currentRootPath || 'config');
  const [subPath, setSubPath] = useState('');

  function switchMenu( _url ){
    return (event)=>{
      const [, currentRootPath] = _url.split('/');
      const [currentItem] = menuData.filter(({ url }) => url === _url );
      const subItemPath = currentItem.children[0]?.children[0]?.url || '';
      const [, , currentSubPath = ''] = subItemPath.split('/') || [];
      setRootPath(currentRootPath);
      setSubPath(currentSubPath);
    }
  }

  return (
      <BrowserRouter>
      <Switch>
      <Route>
        <Layout>
          <Body>
            <Aside
              menuData={menuData}
              rootPath={rootPath}
              subPath={subPath}
              mainItemRender={(el, href = '') => {
                return <NavLink to={`${href}`} onClick={switchMenu(`${href}`)}>{el}</NavLink>;
              }}
            >
              <Logo />
            </Aside>
            <Main>
              <Container >
                  <Switch>
                    <Route exact path="/">
                      <Redirect to="/config" />
                    </Route>
                    <Route path="/config" component={Config} />
                  </Switch>
              </Container>
            </Main>
          </Body>
        </Layout>
      </Route>
      </Switch>
      </BrowserRouter>
  );
};

export default hot(App);
