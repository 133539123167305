import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './components/Style';

/**
 * TODO document.getElementById('fengye') 这个使用的html模板是src目录下的，不是很合理，需要调整
 */
ReactDOM.render(<App />, document.getElementById('nobury'));

